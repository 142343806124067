<template>
  <div class="basket">
    <!--(Початок). Контейнер із інформацією-->
    <div class="grey lighten-4">
      <v-container fluid class="container-max-width py-0 grey--text text--darken-2">
        <div class="grey lighten-4 py-4">
          <v-row no-gutters class="flex-nowrap" align="start">
            <span>
              <v-icon
                  size="56px"
                  color="primary"
                  class="pr-4">
                mdi-cart-outline
              </v-icon>
            </span>
            <div>
              <h3 class="text-uppercase">Корзина</h3>
              <p class="mb-1">Тут Ви зможете підтвердити, відмінити або переглянути створені вами платежі, а також
                відслідкувати історію</p>
            </div>
          </v-row>
        </div>
      </v-container>
    </div>
    <!--(Кінець). Контейнер із інформацією-->

    <!-- (Початок). Основний контейнер для усієї сторінки-->
    <v-container fluid class="container-max-width pt-0">
      <v-card class="mb-2" min-height="140px">
        <v-list class="py-0">
          <v-list-item no-action :class="`${$vuetify.breakpoint.smAndUp ? 'basket-title' : 'basket-title-xs'}`">
            <v-list-item-icon>
              <v-row no-gutters align="center">
                    <span class="selectAllCart">
                      <v-checkbox hide-details
                                  color="success darken-1"
                                  class="check_box_selected pl-2 pr-1 mb-1 mt-0"
                                  v-model="selectAll"
                                  @change="selectAllCart"
                      />
                    </span>
              </v-row>
            </v-list-item-icon>
            <v-list-item-content class="py-1 my-0">
              <v-row no-gutters class="grey--text body-2 font-weight-light" align="center">
                <v-col :cols="cols(0, 2, 4, 6)" md="2" sm="4" xs="6">
                  Операція
                </v-col>
                <v-col :cols="cols(0, 2, 2, 6)" md="2" sm="2" xs="6"
                       class="hidden-sm-and-up text-right text-sm-left text-md-left pr-4"
                       :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1' : ''}`">
                  Сума
                </v-col>
                <v-col :cols="cols(0, 2)" md="2" class="hidden-sm-and-down">
                  Дата
                </v-col>
                <v-col :cols="cols(0, 4, 8, 12)" md="4" sm="8" xs="12">
                  Назва
                </v-col>
                <v-col :cols="cols(0, 0, 4, 6)" sm="4" xs="6" class="hidden-md-and-up"
                       :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1' : ''}`">
                  Дата
                </v-col>
                <v-col :cols="cols(0, 2, 2, 6)" md="2" sm="2" xs="6"
                       :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1 pr-4 text-right' : ''}`">
                  Статус
                </v-col>
                <v-col :cols="cols(0, 1, 8, 6)" md="1" sm="8" xs="6" class="hidden-xs-only"
                       :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1' : ''}`">
                  Сума
                </v-col>
                <v-col :cols="cols(0, 1)" md="1" class="hidden-sm-and-down">
                  <v-btn small outlined fab color="white">
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-action v-if="$vuetify.breakpoint.smOnly" class="delete-row-action"/>
            <span :class="`${$vuetify.breakpoint.xsOnly ? 'empty-box-xs' : 'empty-box'}`"/>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list class="py-0">
          <v-list-group v-for="(item, index) in basketList" :key="index" class="pa-0"
                        :class="`${$vuetify.breakpoint.xsOnly ? 'basket-xs' : 'basket-item'} ${!item.services.length ? 'basket-item-non-expansion' : ''}`">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-row no-gutters align="center">
                    <span>
                      <v-checkbox hide-details
                                  color="success darken-1"
                                  :input-value="item.selected"
                                  class="check_box_selected pl-2 pr-1 mb-1 mt-0"
                                  :class="`${item.state === 'checkedout' ? 'check_box_locked' : ''}`"
                                  :off-icon="`${item.state === 'checkedout' ? 'mdi-lock-outline' : 'mdi-checkbox-blank-outline'}`"
                                  @click.stop="changeSelect(index)"
                      />
                    </span>
                  <span class="hidden-xs-only">
                          <v-icon size="24px" :color="getImageClass(item)" class="pr-2">{{ getImage(item) }}</v-icon>
                    </span>
                </v-row>
              </v-list-item-icon>
              <v-list-item-content class="py-2">
                <v-row no-gutters class="grey--text text--darken-3 body-2 font-weight-light " align="center">
                  <v-col :cols="cols(0, 2, 4, 8)" md="2" sm="4" xs="8">
                    {{ item.operation }}
                  </v-col>
                  <v-col :cols="cols(0, 2, 2, 4)" md="2" sm="2" xs="4"
                         class="hidden-sm-and-up text-right text-sm-left text-md-left font-weight-regular"
                         :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1' : ''}`">
                    {{ item.sum | hrn }}
                  </v-col>
                  <v-col :cols="cols(0, 2)" md="2" class="hidden-sm-and-down">
                    <!--                      {{ item.log ? formatDate(item.log.created.date) : '' }}-->
                    {{ getDateFromLog(item) }}
                  </v-col>
                  <v-col :cols="cols(0, 4, 8, 12)" md="4" sm="8" xs="12" class="pr-3">
                    {{ item.address ? item.address.title : '' }}
                  </v-col>
                  <v-col :cols="cols(0, 0, 4, 6)" sm="4" xs="6" class="hidden-md-and-up"
                         :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1' : ''}`">
                    <!--                      {{ item.log ? formatDate(item.log.created.date) : '' }}-->
                    {{ getDateFromLog(item) }}
                  </v-col>
                  <v-col :cols="cols(0, 2, 4, 6)" md="2" sm="4" xs="6"
                         class="state-item text-right text-sm-left text-md-left">
                    <v-chip
                        small
                        outlined
                        class="font-weight-regular"
                        :class="`${$vuetify.breakpoint.smAndDown ? 'mt-1' : ''}`"
                        :color="getChipClass(item)"
                    >
                      {{ getStateTranslate(item.state) }}
                    </v-chip>
                  </v-col>
                  <v-col :cols="cols(0, 1, 4, 6)" md="1" sm="4" xs="6" class="hidden-xs-only text-md-left"
                         :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1' : $vuetify.breakpoint.smOnly ? 'pr-3' : ''}`">
                    {{ item.sum | hrn }}
                  </v-col>
                  <v-col :cols="cols(0, 1)" md="1" class="hidden-sm-and-down">
                    <confirmDialog @confirmModal="confirmRemoveRow" :item="index" :small="true" :fab="true"
                                   :outlined="true" :text="false" icon="mdi-delete-variant" :right="true"
                                   :divider="false" :headerColor="'error white--text mb-1'">
                      <template v-slot:header>
                        Вилучити елемент із корзини?
                        <v-divider/>
                      </template>
                      <template v-slot:content>Після вилучення елемента із корзини, відновити Ви його не зможете, Вам
                        потрібно заново створити його.
                      </template>
                    </confirmDialog>
                  </v-col>
                  <v-col :cols="cols(0, 0, 0, 12)" xs="12" v-if="$vuetify.breakpoint.xsOnly" class="pt-2">
                    <confirmDialog @confirmModal="confirmRemoveRow" :item="index" :small="true" :fab="false"
                                   :outlined="false" :text="false" :block="true" icon="mdi-delete-variant"
                                   :showIcon="false" :divider="false" :headerColor="'error white--text mb-1'">
                      <template v-slot:buttonName>
                        Вилучити із корзини
                      </template>
                      <template v-slot:header>
                        Вилучити елемент із корзини?
                        <v-divider/>
                      </template>
                      <template v-slot:content>Після вилучення елемента із корзини, відновити Ви його не зможете, Вам
                        потрібно заново створити його.
                      </template>
                    </confirmDialog>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-action v-if="$vuetify.breakpoint.smOnly" class="delete-row-action text-sm-left">
                <confirmDialog @confirmModal="confirmRemoveRow" :item="index" :small="true" :fab="true" :outlined="true"
                               :text="false" icon="mdi-delete-variant" :align="'start'" :divider="false"
                               :headerColor="'error white--text mb-1'">
                  <template v-slot:header>
                    Вилучити елемент із корзини?
                  </template>
                  <template v-slot:content>Після вилучення елемента із корзини, відновити Ви його не зможете, Вам
                    потрібно заново створити його.
                  </template>
                </confirmDialog>
              </v-list-item-action>
            </template>
            <v-list-item
                v-for="(serviceItem, index) in item.services"
                :key="index"
            >
              <v-list-item-content class="py-1">
                <v-row no-gutters align="center">
                  <v-col :cols="cols(0, 7, 12, 12)" md="7" sm="12" xs="12" class="pl-2">
                    <v-row no-gutters class="border-left caption grey--text text--darken-2">
                      <v-col :cols="cols(0, 4, 4, 5)" md="4" sm="4" xs="5" class="pl-2">
                        <span class="grey--text font-weight-light pt-1">Послуга</span>
                        <p class="mb-2 text-truncate">{{ serviceItem.service.name }}</p>
                      </v-col>
                      <v-col :cols="cols(0, 4, 4, 4)" md="4" sm="4" xs="4" class="text-md-right">
                        <span class="grey--text pt-1 font-weight-light">Особовий рахунок</span>
                        <p class="mb-2 text-truncate">{{ serviceItem.person_id_internal_text }}</p>
                      </v-col>
                      <v-col :cols="cols(0, 4, 4, 3)" md="4" sm="4" xs="3" class="text-md-right text-xs-right">
                        <span class="grey--text pt-1 font-weight-light text-md-right text-xs-right">Сума</span>
                        <p class="mb-2 text-md-right text-xs-right text-truncate">{{ serviceItem.sum | hrn }}</p>
                      </v-col>
                    </v-row>
                    <v-divider class="grey lighten-3"/>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <!--              <v-divider class="grey lighten-3"></v-divider>-->
          </v-list-group>
        </v-list>
        <v-list v-if="!basketList.length">
          <v-list-item-content>
            <span class="text-center my-5">Корзина порожня, спершу створіть платежі</span>
          </v-list-item-content>
        </v-list>
        <v-list>
          <v-divider></v-divider>
          <v-list-item>
            <v-row no-gutters align="center">
              <v-col :cols="cols(0, 12, 12, 12)" md="12" sm="12" xs="12" v-if="basketList.length" class="mt-2">
                <span class="subtitle-1 font-weight-bold green--text" style="letter-spacing: 1px !important;">Всього до оплати: </span>
                <span>{{ getTotalSum() | hrn }}</span>
              </v-col>
              <v-col :cols="cols(0, 12, 12, 12)" md="12" sm="12" xs="12" class="text-md-end text-sm-end"
                     v-if="basketList.length">
                <div class="d-inline-flex mr-2 body-2 font-weight-regular"
                     :class="`${$vuetify.breakpoint.smAndUp ? 'switch-align' : ''}`">
                  <v-switch v-model="termAccept" hide-details class="mt-0 pt-0">
                    <template v-slot:label>
                      <span>Приймаю умови <a target="_blank" @click.stop
                                             :href="`${publicPath}files/tas/public_offer.pdf`"
                                             style="text-decoration: none">договору оферти</a></span>
                    </template>
                  </v-switch>
                </div>
                <div class="hidden-sm-and-up" style="width: 100%">
                  <div class="d-inline-flex" style="width: 100%">
                    <div class="text-left" style="width: 50%">
                      <v-menu top :close-on-click="true" offset-y :nudge-width="160" style="background-color: grey !important;">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="primary" class="mr-2 body-2"
                                 :class="`${$vuetify.breakpoint.xsOnly ? 'mt-2' : ''}`"
                                 :disabled="!readyToConfirm || !termAccept"
                                 v-bind="attrs" v-on="on">
                            Оплатити
                          </v-btn>
                        </template>

                        <v-list class="ma-0 px-5 pt-2 pb-6">
                          <div class="mb-2 grey--text text--darken-2">Оберіть спосіб оплати</div>
                          <v-list-item @click.stop="" class="grey lighten-3 elevation-3 mb-3" @click="confirmCart(1)">
                            <v-list-item-icon class="my-1 ma-auto">
                              <img :src="`${publicPath}images/payments/Apple_Pay_logo.svg`" alt="apple_pay" height="46" width="46">
                            </v-list-item-icon>
                          </v-list-item>

                          <v-list-item @click.stop="" class="grey lighten-3 elevation-3 mb-3" @click="confirmCart(1)">
                            <v-list-item-icon class="my-1 ma-auto" >
                              <img :src="`${publicPath}images/payments/google_pay.svg`" alt="google_pay" height="46" width="46">
                            </v-list-item-icon>
                          </v-list-item>

                          <v-list-item @click.stop=""
                                       class="grey lighten-3 elevation-3 py-1 flex align-center"
                                       @click="confirmCart(0)">
                            <v-icon color="primary" size="22">
                              mdi-credit-card
                            </v-icon>
                            <div class="ma-auto">Ввести номер картки</div>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <div class="text-right" style="width: 50%">
                      <v-btn x-small fab depressed dark color="success" class="body-2 mr-2" @click="reloadData"
                             :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1' : ''}`">
                        <v-icon small>
                          mdi-reload
                        </v-icon>
                      </v-btn>
                      <v-btn x-small fab depressed dark color="error" class="body-2" @click="emptyCartModal = true"
                             :class="`${$vuetify.breakpoint.xsOnly ? 'mt-1' : ''}`">
                        <v-icon small>
                          mdi-delete-sweep-outline
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="hidden-xs-only" :class="`${$vuetify.breakpoint.smAndUp ? 'd-inline-block' : ''}`">
                  <div class="d-inline-block">
                    <v-menu top :close-on-click="true" offset-y :nudge-width="160" style="background-color: grey !important;">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="primary" class="mr-2 body-2"
                                 :class="`${$vuetify.breakpoint.xsOnly ? 'mt-2' : ''}`"
                                 :disabled="!readyToConfirm || !termAccept"
                                 v-bind="attrs" v-on="on">
                            Оплатити
                          </v-btn>
                        </template>

                        <v-list class="ma-0 px-5 pt-2 pb-6">
                          <div class="mb-2 grey--text text--darken-2">Оберіть спосіб оплати</div>
                          <v-list-item @click.stop="" class="grey lighten-3 elevation-3 mb-3" @click="confirmCart(1)">
                            <v-list-item-icon class="my-1 ma-auto">
                              <img :src="`${publicPath}images/payments/Apple_Pay_logo.svg`" alt="apple_pay" height="46" width="46">
                            </v-list-item-icon>
                          </v-list-item>

                          <v-list-item @click.stop="" class="grey lighten-3 elevation-3 mb-3" @click="confirmCart(1)">
                            <v-list-item-icon class="my-1 ma-auto" >
                              <img :src="`${publicPath}images/payments/google_pay.svg`" alt="google_pay" height="46" width="46">
                            </v-list-item-icon>
                          </v-list-item>

                          <v-list-item @click.stop=""
                                       class="grey lighten-3 elevation-3 py-1 flex align-center"
                                       @click="confirmCart(0)">
                            <v-icon color="primary" size="22">
                              mdi-credit-card
                            </v-icon>
                            <div class="ma-auto">Ввести номер картки</div>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    <v-tooltip top color="success">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            small
                            fab
                            depressed
                            dark
                            color="success"
                            class="body-2 mr-2"
                            @click="reloadData"
                            :class="`${$vuetify.breakpoint.xsOnly ? 'mt-2' : ''}`">
                          <v-icon small>
                            mdi-reload
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Оновити статус оплати</span>
                    </v-tooltip>
                    <v-tooltip top color="error">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            small
                            fab
                            depressed
                            dark
                            color="error"
                            class="body-2"
                            @click="emptyCartModal = true"
                            :class="`${$vuetify.breakpoint.xsOnly ? 'mt-2' : ''}`">
                          <v-icon small>
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Очистити корзину</span>
                    </v-tooltip>
                  </div>
                </div>
                <confirmDialog @confirmModal="confirmEmptycart" v-model="emptyCartModal" :useParentDialog="true"
                               :parentDialog="emptyCartModal" :showButton="false" :divider="false"
                               :headerColor="'error white--text mb-1'">
                  <template v-slot:buttonName>
                  </template>
                  <template v-slot:header>
                    Очистити усі записи із корзини?
                  </template>
                  <template v-slot:content>Якщо Ви очистите корзину - усі підготовлені до оплати записи зникнуть, але Ви
                    зможете повторно їх додати
                  </template>
                </confirmDialog>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>

  </div>
</template>

<script>
import {CHANGE_CART_COUNT, CLEAR_CART_COUNT} from '@/store/modules/cart/mutation-types';
import confirmDialog from '@/components/global/confirmDialog';
import {CartAPI} from '@/api/cart'
import {PaymentTasAPI} from '@/api/tasPayments'
import {map, assign, uniqBy, each, cloneDeep} from 'lodash'
import {checkout, google_pay } from "@/utils";
import {
  getImage,
  getImageClass,
  getChipClass,
  getStateTranslate,
  getOperationType,
  getOperation,
  formatDate,
  cols,
  getServices
} from '@/utils'
import {SET_CART_COUNT} from '@/store/modules/cart/mutation-types';

export default {
  name: "cart-component",
  components: {
    confirmDialog,
  },
  data() {
    return {
      basketList: [],
      originData: [],
      selectAll: false,
      readyToConfirm: false,
      termAccept: false,
      emptyCartModal: false,
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    checkout,
    google_pay,
    async getCart() {
      try {
        let {data} = await CartAPI.view({'acquiring': 'tas'});
        this.originData = cloneDeep(data);

        let basketList = map(data, (item) => {
          return assign(item, {
            person_id_internal: null,
            service: {},
            sum: 0,
            selected: true,
            state: item.payment !== null ? item.payment.state : 'created',
          })
        });


        basketList = map(uniqBy(basketList, 'group_id'), (item) => {
          return assign(item, {
            services: this.getServices(item),
            operation_type: this.getOperationType(item),
            operation: this.getOperation(item),
            selected: true,
          })
        });

        this.basketList = each(basketList, item => {
          item.sum = item.services.reduce((total, s) => {
            return total + s.sum;
          }, 0);
          item.selected = item.state === 'created';
        });

        await this.$store.dispatch(SET_CART_COUNT, basketList.length).then(() => {
          this.$eventBus.$emit('afterGetCart');
        });

      } catch (e) {
        this.$snackbar("Помилка одержання корзини", 'error')
      }
    },
    async removeCartRow(item) {
      try {
        const currentRow = this.basketList[item];
        let paymentsList = {payments: [currentRow.group_id]};
        await CartAPI.remove(paymentsList);
        await this.$store.dispatch(CHANGE_CART_COUNT, -1);
        this.basketList.splice(item, 1);
      } catch (e) {
        this.$snackbar("Помилка вилучення", 'error')
      }
    },
    async emptyCart() {
      try {
        await CartAPI.empty();
        await this.$store.dispatch(CLEAR_CART_COUNT);
        this.basketList = [];
      } catch (e) {
        this.$snackbar("Помилка очищення", 'error')
      }
    },
    confirmWithoutGooglePay() {
      const {paymentsList} = this.getPaymentsForConfirm()
      this.$router.push({
        name: 'cards-list',
        query: {payments: paymentsList.payments, total: this.getTotalSum()}
      })
    },
    AuthDirectGooglePay() {
      const {paymentsList} = this.getPaymentsForConfirm()
      this.checkout(null, paymentsList.payments)
          .then((payload) => {
            if (payload.id) {
              this.google_pay(payload.id)
                  .then((confirmPayload) => {
                    if (confirmPayload.url) {
                      window.location.href = confirmPayload.url;
                    } else {
                      this.$router.push({ name: 'confirmCheckout', query: { payments: paymentsList.payments }});
                    }
                  }).catch(() => {
                this.$snackbar("Помилка підтвердження платежу", 'error')
              })
            }
          });
    },
    confirmCart(operation=0) {
      if (operation === 0) {
        this.confirmWithoutGooglePay()
      } else {
        this.AuthDirectGooglePay()
      }
    },
    async checkState() {
      try {
        let uniqId = [];
        await each(this.basketList, item => {
          if (item.payment) {
            if (!uniqId.includes(item.payment.id)) {
              uniqId.push(item.payment.id)
            }
          }
        });

        if (uniqId.length) {
          await PaymentTasAPI.state({'acquiring': 'tas', 'payments': uniqId});
        }

      } catch (e) {
        this.$snackbar("Помилка перевірки статусу платежа", 'error')
      }
    },
    getPaymentsForConfirm() {
      let selectedServices = this.basketList.filter(item => item.selected && item.sum);
      let paymentsList = {payments: []};

      paymentsList.payments = map(selectedServices, (item) => {
        return item.group_id;
      });

      return {paymentsList, counter: selectedServices.length}
    },
    changeStateToPending() {
      let selectedServices = this.basketList.filter(item => item.selected && item.sum);
      each(selectedServices, item => {
        item.state = 'checkedout';
        item.selected = false;
      });
    },
    getTotalSum() {
      const totalSum = this.basketList.reduce((total, s) => {
        let currentSum = s.selected ? s.sum : 0;
        return total + currentSum;
      }, 0);
      this.readyToConfirm = !!totalSum;

      return totalSum;
    },
    selectAllCart() {
      each(this.basketList, item => {
        item.selected = this.selectAll;
      });
      this.getTotalSum();
    },
    changeSelect(e) {
      let currentRow = this.basketList[e];
      if (currentRow.state !== 'checkedout') {
        currentRow.selected = !currentRow.selected;
      } else {
        currentRow.selected = false;
      }
      this.$emit('change', currentRow.selected);
      this.getTotalSum();
    },
    confirmRemoveRow(value) {
      if (value.question) {
        this.removeCartRow(value.item);
      }
    },
    confirmEmptycart(value) {
      if (value.question) {
        this.emptyCart();
        this.emptyCartModal = false;
      }
    },
    async reloadData() {
      await this.checkState();
      await this.getCart();
    },
    getDateFromLog(item) {
      if (item.payment === null) {
        return this.formatDate(item.create_date);
      }
      return this.formatDate(item.payment.log[item.state].date)
    },
    cols,
    getOperationType,
    getOperation,
    formatDate,
    getServices,
    getImage,
    getImageClass,
    getChipClass,
    getStateTranslate
  },
  async mounted() {
    await this.getCart();
  }
}
</script>

<style scoped lang="scss">
.container-max-width {
  max-width: 1200px;
}

.switch-align {
  top: 3px;
  position: relative;
}

.basket-title {
  &::v-deep .v-btn__content i.v-icon.notranslate.material-icons.theme--light {
    display: none !important;
  }

  &::v-deep .v-list-item__icon.v-list-group__header__append-icon i.v-icon.notranslate.material-icons.theme--light {
    display: none !important;
  }
}

.basket-item-non-expansion {
  &::v-deep .v-list-item__icon.v-list-group__header__append-icon i.v-icon.notranslate.material-icons.theme--light {
    display: none !important;
  }
}

.basket-item,
.basket-title {
  &::v-deep .v-list-item__icon.v-list-group__header__append-icon {
    margin-left: 0;
    min-width: 24px;
  }
}


.basket-item {
  &::v-deep .v-list-item__action:first-child, .v-list-item__icon:first-child {
    margin-right: 7px;
  }
}

.basket-title {
  &::v-deep .v-list-item__action:first-child, .v-list-item__icon:first-child {
    margin-right: 39px;
  }
}

.basket-title-xs {
  &::v-deep .v-list-item__action:first-child, .v-list-item__icon:first-child {
    margin-right: 1px;
  }
}

.empty-box {
  width: 26px;
  height: 24px;
}

.empty-box-xs {
  width: 33px;
  height: 24px;
}

.basket-xs {
  &::v-deep .v-list-item__icon {
    margin: 28px 0;
  }

  &::v-deep .v-list-item__icon.v-list-group__header__append-icon {
    margin-left: 0px !important;
  }
}

.delete-row-action {
  text-align: left;
  min-width: 64px !important;
}

.border-left {
  border-left: 6px solid #95bde4;
}

.history-list {
  &::v-deep .v-list-item {
    min-height: 38px !important;
  }

  &::v-deep .v-list-item__icon {
    margin: 8px 8px 8px 0 !important;
  }
}

.history-item {
  border-bottom: .5px solid #efefef;
}

.state-item {
  &::v-deep .v-chip {
    width: 80px !important;
    position: relative;
  }

  &::v-deep .v-chip__content {
    position: absolute !important;
    left: 50% !important;
    transform: translate(-50%) !important;
  }
}

.check_box_locked {
  &::v-deep .v-icon.notranslate.mdi {
    color: orange;
    font-size: 20px;
    padding-left: 2px;
  }
}
</style>
