<template>
  <div>
    <CartBase></CartBase>
    <CartHistory></CartHistory>
  </div>
</template>

<script>
  import CartBase from '@/components/payments/CartBase'
  import CartHistory from "@/components/payments/CartHistory"

    export default {
      name: "cart-view",
      components: {
        CartBase,
        CartHistory
      }
    }
</script>
