<template>
    <div class="cartHistory">
      <div class="grey lighten-4">
        <v-container fluid class="container-max-width py-0 grey--text text--darken-2">
          <div class="grey lighten-4 py-4">
            <v-row no-gutters class="flex-nowrap" align="start">
              <span>
                <v-icon
                  size="56px"
                  color="primary"
                  class="pr-4">
                  mdi-history
                </v-icon>
              </span>
              <div>
                <h3 class="text-uppercase">Історія платежів, здійснених операцій</h3>
                <p class="mb-1">Тут Ви зможете переглянути історію Ваших операцій, платежів, переданих показів...</p>
              </div>
            </v-row>
          </div>
        </v-container>
      </div>

      <v-container fluid class="container-max-width pt-0">
        <v-card class="mb-2" min-height="110px">
          <v-list class="py-0" disabled>
            <v-list-item class="pr-1">
                <v-list-item-icon class="mr-2">
                </v-list-item-icon>
                <v-list-item-content class="py-1 my-0">
                  <v-row no-gutters  class="grey--text body-2 font-weight-light"  align="center">
                    <v-col :cols="cols(0, 2, 3, 6)" md="2" sm="3" xs="6">
                      Операція
                    </v-col>
                    <v-col :cols="cols(0, 2, 2, 6)" md="2" sm="2" xs="6">
                      Дата
                    </v-col>
                    <v-col :cols="cols(0, 5, 7, 12)" md="5" sm="7" xs="12">
                      Назва
                    </v-col>
                    <v-col :cols="cols(0, 2, 5, 6)" md="2" sm="5" xs="6" class="text-md-center">
                      Статус
                    </v-col>
                    <v-col :cols="cols(0, 1, 7, 6)" md="1" sm="7" xs="6" class="text-md-right">
                      Сума
                    </v-col>
                  </v-row>
                </v-list-item-content>
              <v-list-item-icon style="flex: 0 0 32px"></v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-divider/>
          <v-list class="py-0 history-list">
            <v-list-item v-for="(item, index) in historyList"
                         :key="index" class="history-item pr-1"
                         :style="index < historyList.length - 1 ? 'border-bottom: .5px solid #4e66793d;' : ''"
            >
                <v-list-item-icon class="mr-2">
                  <v-icon size="24px" :color="getImageClass(item)">{{ getImage(item) }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-2">
                  <v-row no-gutters  class="grey--text text--darken-3 body-2 font-weight-light"  align="center">
                    <v-col :cols="cols(0, 2, 3, 6)" md="2" sm="3" xs="6">
                      {{ item.operation }}
                    </v-col>
                    <v-col :cols="cols(0, 2, 2, 6)" md="2" sm="2" xs="6">
                      {{ `${formatDate(item.state_date, 0,'month','DD.MM.YYYY')} ${(item.state_time || '').substring(0, 8)}` }}
                    </v-col>
                    <v-col :cols="cols(0, 5, 7, 12)" md="5" sm="7" xs="12" :class="`${$vuetify.breakpoint.xsOnly ? 'mt-2' : ''}`">
                      {{ item.address_title }}
                    </v-col>
                    <v-col :cols="cols(0, 2, 5, 6)" md="2" sm="5" xs="6" class="state-item text-md-center">
                      <v-chip
                        small
                        outlined
                        class="font-weight-bold"
                        :class="`${$vuetify.breakpoint.smAndDown ? 'mt-2' : ''}`"
                        :color="getChipClass(item)"
                      >
                        {{ getStateTranslate(item.state) }}
                      </v-chip>
                    </v-col>
                    <v-col :cols="cols(0, 1, 7, 6)" md="1" sm="7" xs="6" class="text-md-right">
                      <span :class="`${$vuetify.breakpoint.smAndDown ? 'mt-2' : ''}`">
                        {{ item.sum | hrn }}
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              <v-list-item-icon>
                <v-btn small icon class="grey lighten-3 mr-2"
                       v-if="item.receipt_path"
                       :href="item.receipt_path"
                       target="_blank"
                       height="24"
                       width="24"
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-list v-if="!historyList.length">
            <v-list-item-content>
              <span class="text-center my-5">Історія операцій порожня</span>
            </v-list-item-content>
            <v-divider/>
            <v-list-item-content>
              <span class="my-3"></span>
            </v-list-item-content>
          </v-list>
          <v-divider v-if="showPagination"/>
          <v-row v-if="showPagination">
            <v-col md="12" sm="12" class="text-center">
              <div class="text-center d-inline-flex align-center">
                <v-btn text small color="success" :disabled="offset===0" @click="pagination('left')" style="font-size: 13px">
                  <v-icon left>mdi-arrow-left-bold</v-icon>
                  Попередня
                </v-btn>
                <v-btn fab text small color="success" class="mx-2" @click="pagination('start')" :disabled="offset===0">
                  <v-icon>mdi-home</v-icon>
                </v-btn>
                <v-btn text small color="success" @click="pagination('right')" :disabled="!nextPage" style="font-size: 13px">
                  Наступна
                  <v-icon right>mdi-arrow-right-bold</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
</template>

<script>
  import {CartAPI} from '@/api/cart'
  import { map, assign, cloneDeep, } from 'lodash'
  import {PAY_STATUS, OPERATION_TYPE, getOperationType, getOperation, formatDate, cols} from '@/utils'

  export default {
    name: "CartHistory",
    data() {
      return {
        originData: [],
        historyList: [],
        page: 1,
        offset: 0,
        limit: 10,
      }
    },
    computed: {
      nextPage() {
        if (this.historyList.length === 0 && this.offset === 0) {
          return true;
        } else {
          return this.historyList.length >= this.limit;
        }
      },
      showPagination() {
        return this.historyList.length > 0 || this.offset > 0;
      }
    },
    methods: {
      async getHistory() {
        try {
          let {data} = await CartAPI.history(this.offset, this.limit);
          this.originData = cloneDeep(data);
          this.historyList =  map(data, (item) => {
            return assign(item, {
                operation_type: this.getOperationType(item),
                operation: this.getOperation(item),
                state: item.state ? item.state : 'created',
            })
          });
        } catch (e) {
            this.$snackbar("Історія операцій не отримана", 'error')
        }
      },
      getImage(item) {
          return OPERATION_TYPE[item.operation_type].icon || 'mdi-credit-card';
      },
      getImageClass(item) {
          return OPERATION_TYPE[item.operation_type].imageClass || 'warning';
      },
      getChipClass(item) {
          return PAY_STATUS[item.state].class || 'info';
      },
      getStateTranslate(state) {
          return PAY_STATUS[state].ukr || state;
      },
      pagination(direction) {
          if (direction === 'left') {
              this.offset = this.offset === 0 ? 0 : this.offset - this.limit;
          } else if (direction === 'right') {
              this.offset += this.limit;
          } else {
              this.offset = 0;
          }
          this.getHistory();

      },
      cols,
      formatDate,
      getOperation,
      getOperationType
    },
    mounted() {
      this.$eventBus.$on('afterGetCart', () => {
        this.getHistory();
      })
        // this.getHistory();
    }
  }
</script>

<style scoped lang="scss">
  .container-max-width {
    max-width: 1200px;
  }

</style>
